import { Component, OnInit, Input } from '@angular/core';
import { Router} from '@angular/router';

@Component({
  selector: 'app-lost-cart',
  templateUrl: './lost-cart.component.html',
  styleUrls: ['./lost-cart.component.scss']
})


export class LostCartComponent implements OnInit {
  @Input() url: string;
  @Input() type: string;
  @Input() name: string;
  @Input() town: string;
  @Input() date: string;
  @Input() id: string;
  @Input() description: string;
  @Input() country: string;
  constructor(private router: Router) { }

  color = "";

  ngOnInit() {
    
  }
 
  openDetail( url : string) {
    let item = {
      id : this.id,
     recompense  : '',
     type :  this.type,
     proprio  : this.name,
     date  : this.date,
     info  : this.description,
     lieu  : this.town,
     urlImg  : this.url,
    }
    localStorage.setItem('item', JSON.stringify(item));
    this.router.navigate([url,this.id]);
  } 

   selectColor() : string{
    if(this.color.length<=0){
      this.color = "block-"+this.getRandomInt(100)%4;
    }
    return this.color;
  }

   getRandomInt(max:number):number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getTown(){
    var txt = this.town;
    if(this.town.length>18){
      txt = this.town.substring(0,18)+"...";
    }
    return txt;
  }

  getName(){
    var txt = this.name;
    if(this.name.length>18){
      txt = this.name.substring(0,18)+"...";
    }
    return txt;
  }

}


