import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { DynamicScriptLoaderService } from '../script-load-service.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
  @Input() activeMenu: number;

  constructor(private dynamicScriptLoader: DynamicScriptLoaderService, private router: Router) {
    router.events.subscribe((val) => {
      if (!(val instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  ngOnInit() {
  }

  isActive(menu: number) {
    var isActive: string;
    isActive = '';
    if (this.activeMenu == menu) {
      isActive = 'active';
    }
    return isActive;

  }


  ngAfterViewInit() {
    this.loadScripts();
  }

  private loadScripts() {
    // You can load multiple scripts by just providing the key as argument into load method of the service
    this.dynamicScriptLoader.load('script').then(data => {
      // Script Loaded Successfully
    }).catch(error => console.log(error));


  }
}
