import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from "ngx-cookie-service";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
clickEvent = new MouseEvent("click", {
    "view": window,
    "bubbles": true,
    "cancelable": false
});
  
itemId;
constructor(private _cookieService: CookieService,private activatedRoute: ActivatedRoute) {
  this.itemId = this.activatedRoute.snapshot.paramMap.get('found');
}

  ngOnInit() {
    if(this._cookieService.get("contract")){
     
    }else{
      document.getElementById("showContract").dispatchEvent(this.clickEvent);
    }


  }

  validContract(){
    this._cookieService.set("contract", "yes",100);
    document.getElementById("closeContract").dispatchEvent(this.clickEvent);
  }


}
