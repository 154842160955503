import { Component, OnInit, ViewChild } from '@angular/core';
import { Ientity } from './i-foundEntity/Ientity';
@Component({
  selector: 'app-i-found',
  templateUrl: './i-found.component.html',
  styleUrls: ['./i-found.component.scss']
})
export class IFoundComponent implements OnInit {
  @ViewChild('openIFound', { static: false }) open: any;
  @ViewChild('closeIFound', { static: false }) close: any;
  static openIFound: any;
  static information: Ientity = new Ientity("dsd", "dsd", "dsd", "d","");
  static step: number;
  static maxstep = 3;
  constructor() { }

  ngOnInit() {
    IFoundComponent.step = 1;
  }

  ngAfterViewInit() {
    IFoundComponent.openIFound = this.open;
  }

  getType() {

    return IFoundComponent.information.type;
  }
  getName() {
    return IFoundComponent.information.nom;
  }

  getLieu() {
    return IFoundComponent.information.lieu;
  }
  getUrl() {
    return IFoundComponent.information.url;
  }

  static changeStepMenu(activeStep: number) {
    document.getElementById("numberStep1").classList.remove("actif");
    document.getElementById("numberStep2").classList.remove("actif");
    document.getElementById("numberStep3").classList.remove("actif");
    switch (activeStep) {
      case 1:
        document.getElementById("numberStep1").classList.add("actif");
        break;
      case 2:
        document.getElementById("numberStep2").classList.add("actif");
        break;
      case 3:
        document.getElementById("numberStep3").classList.add("actif");
        break;
      case 4:
        document.getElementById("numberStep4").classList.add("actif");
        break;
    }
  }

  static changeStepContent(activeStep: number) {
    document.getElementById("step1").style.display = "none";
    document.getElementById("step2").style.display = "none";
    document.getElementById("step3").style.display = "none";
    switch (activeStep) {
      case 1:
        document.getElementById("step1").style.display = "block";
        break;
      case 2:
        document.getElementById("step2").style.display = "block";
        break;
      case 3:
        document.getElementById("step3").style.display = "block";
        break;
      case 4:
        document.getElementById("step4").style.display = "block";
        break;
    }
  }

  static changeStep(step: any) {
    this.changeStepMenu(step);
    this.changeStepContent(step);
    if (step == this.maxstep) {
      document.getElementById("next2").style.display = "block";
      document.getElementById("next1").style.display = "none";
    } else {
      document.getElementById("next1").style.display = "block";
      document.getElementById("next2").style.display = "none";
    }
    if (step == 1) {
      document.getElementById("previous1").style.display = "block";
      document.getElementById("previous2").style.display = "none";
    } else {
      document.getElementById("previous2").style.display = "block";
      document.getElementById("previous1").style.display = "none";
    }



  }


  static setIdAndShow(info: Ientity) {
    IFoundComponent.information = info;
    IFoundComponent.leanAndShow();
    this.openIFound.nativeElement.click();

  }




  goToPreviousStep() {
    if (IFoundComponent.step == 1) {
      this.close.nativeElement.click();
    } else {
      IFoundComponent.step = IFoundComponent.step - 1;
      IFoundComponent.changeStep(IFoundComponent.step);
    }

  }



  goToNextStep() {
    if (IFoundComponent.saveNumber() || IFoundComponent.step != 2) {
      if (IFoundComponent.step == IFoundComponent.maxstep) {
        this.close.nativeElement.click();
        window.open("/home","_self");
      } else {
        IFoundComponent.step = IFoundComponent.step + 1;
        IFoundComponent.changeStep(IFoundComponent.step);
      }
    }

  }

  saveChoice(id: any) {
    var selector = 'label[for=' + id + ']'
    var label = document.querySelector(selector);
    console.log(id);
    document.getElementById("select_1").innerHTML = label.innerHTML;
  }

  static saveNumber(): boolean {
    var ret = true;
    var txt = (<HTMLInputElement>document.getElementById("numberX")).value;
    document.getElementById("phone").innerHTML = txt;
    ret = txt.length >=9;
    return ret;
  }


  static leanAndShow() {
    IFoundComponent.step = 1;
    (<HTMLInputElement>document.getElementById("numberX")).value = "";
    this.changeStep(IFoundComponent.step);
  }

}
