import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonUrlService } from '../common-url.service';

@Component({
  selector: 'app-humainservice',
  templateUrl: './humainservice.component.html',
  styleUrls: ['./humainservice.component.scss']
})
export class HumainserviceComponent implements OnInit {

  constructor(private urlservice:CommonUrlService) { }

  humain;
  file;
  items =[
  {
    type:"Perdu",
    name:"Koulounou Penja Bahaman",
    town:"Douala Cameroun",
    urlImg:"/assets/images/backgrounds/paris.jpg"
  }
]
state = false;

form : NgForm;
  ngOnInit() {
    this.loadlostHumains();
  }

  loadlostHumains(){
    this.items =[];
    document.getElementById("first_1").style.display = "block";
    this.urlservice.getLostHumains(this.loadlostHumainsObserver);  
  }

  loadlostHumainsObserver = {
    next: (response) => {
      document.getElementById("first_1").style.display = "none";
      this.items = response;
    },
    error: (error) => {
      document.getElementById("first_1").style.display = "none";
      console.log(error);
    },
    complete: (resp) => {  

     }
  }


  addLostHumain(form : NgForm){
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("secondFound").style.display = "none";
    let humain = {
      "nom":form.value['name'],
      "recorderPhone":form.value['phone'],
      "recorderName":"",
      "town": form.value['town'],
      "country": "Cameroun",
      "description": form.value['details'],
      "lostOrFound": this.state,
      "enable": true,
      "isVipService": true,
      "imageUrl": null
    };
    this.humain = humain;
    this.form = form;

    if(this.file!=null){
      this.urlservice.uploadFile(this.file, this.saveImageObserver);  
    }else{
      this.urlservice.addLostHumain(this.humain,this.addLostHumainObserver); 
    }
     
  }

  saveImageObserver = {
    next: (response) => {
      this.humain.imageUrl=response.url;
      if(this.file!=null){
        this.file = null;
        this.urlservice.addLostHumain(this.humain,this.addLostHumainObserver); 
      }
    },
    error: (error) => {
      console.log(error);
    },
    complete: (resp) => {  

     }
  }

  addLostHumainObserver = {
    next: (response) => {
      
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
      this.form.resetForm();
      let link1 = document.getElementById('openValidCommand');
      let link2 = document.getElementById('closeAddHumain');
      link1.click();
      link2.click();
      
    },
    error: (error) => {
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
      console.log(error);
    },
    complete: (resp) => {  
      this.loadlostHumains();
     }
  }

  setState(bool : boolean){
    this.state = bool;
  }
  getState(){
    if(this.state){
      return "trouvée"
    }else{
      return "perdue"
    }
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(files: FileList) {
    this.file  = null;
    if (files.length == 0) {
      return;
    }
    this.file  = files[0];
  }

}
