import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { CommonUrlService } from "../common-url.service";

@Component({
  selector: 'app-found-object',
  templateUrl: './found-object.component.html',
  styleUrls: ['./found-object.component.scss']
})

export class FoundObjectComponent implements OnInit {

  constructor(private httpClient: HttpClient,private urlservice:CommonUrlService) { }
  form: NgForm;
 @ViewChild('openValidate', {static: false}) openModal: any;
 @ViewChild('closeValidate', {static: false}) closeValidate: any;
  file : File; 
  item;
  ngOnInit() {
    
  }

  ngAfterViewInit() {

  }

  before() {
  
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("twoFound").style.display = "none";
    
  }
  after() {
    document.getElementById("twoFound").style.display = "block";
    document.getElementById("firstFound").style.display = "none";

  } 
  saveInfo(form: NgForm){

   this.form =form;
    var type = form.value['typeFound'];
    if(type===""){
		type = form.value['type1'];
    }
    var lieu = form.value['lieuFound'];
    var details = form.value['detailsFound'];
    var tel = form.value['telFound'];
    var name = form.value['nameFound'];
    let item=
      {
        "type":type,
        "country":"Cameroun",
        "town":lieu,
        "description":details,
        "imageUrl":"",
        "recorderPhone":tel,
        "recorderName":"",
        "nom":name
      }
      this.item = item;
      this.before();
      if(this.file!=null){
        this.urlservice.uploadFile(this.file, this.saveImageObserver);  
      }else{
        this.urlservice.saveFoundItem(this.item,this.saveFoundItemObserver);
      }
  }


  saveFoundItemObserver = {
    next: (response) => {
      this.after();
      this.form.resetForm();
      this.openModal.nativeElement.click();
      this.closeValidate.nativeElement.click();
    },
    error: (error) => {
      console.log(error);
      this.after();
    }

  }

  saveToTypeFieldFound(){
    var type =(<HTMLInputElement>document.getElementById("type1Found")).value;
    if((<HTMLInputElement>document.getElementById("type1Found")).value==="other"){
        type=null;
        (<HTMLInputElement>document.getElementById("typeFound")).disabled = false;
        document.getElementById("type_blockFound").style.display = "block";
        
    }else{
      (<HTMLInputElement>document.getElementById("typeFound")).disabled = true;
      document.getElementById("type_blockFound").style.display = "none";
    }
    (<HTMLInputElement>document.getElementById("typeFound")).value=type;
  }

  selectFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(files: FileList) {
    this.file  = null;
    if (files.length == 0) {
    
      return;
    }
    this.file  = files[0];
  }

  saveImageObserver = {
    next: (response) => {
      this.item.imageUrl=response.url;
      if(this.file!=null){
        this.file = null;
        this.urlservice.saveFoundItem(this.item,this.saveFoundItemObserver);
      }
    },
    error: (error) => {
      console.log(error);
      this.after();
    },
    complete: (resp) => {  

     }
  }
}
