import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUrlService } from '../common-url.service';
import { Form, NgForm } from '@angular/forms';

@Component({
  selector: 'app-colabo-detail',
  templateUrl: './colabo-detail.component.html',
  styleUrls: ['./colabo-detail.component.scss']
})
export class ColaboDetailComponent implements OnInit {

  itemId : any;
  id: any;
  recompense = "Oui";
  type = "Carte national";
  proprio = "TRESUL CLOUBER CKIENR CIEN";
  date = "12/02/1990";
  info = "information supplementaire";
  lieu = "Douala bependa";
  urlImg = "/assets/images/noImage.png";
  step: number = 1;
  secure =false;
  notOurSecure = true;
  notifiedOwnerForm : NgForm;
  descriptionItem;
  loading = false;
  errorTransaction = false;


  constructor(private activatedRoute: ActivatedRoute,private urlservice: CommonUrlService,private _router: Router) {
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDataFromServer(this.itemId);
    let itemJson = localStorage.getItem('item');
    let item = JSON.parse(itemJson);
    this.loadData(item);

  }

  ngOnInit() {
   
   
  }

  /*
   TODO 
   actuellement la logique ne permet pas de recuperer les données en DB
   il va falloir mettre en place en get en se basant sur l'id.
   tout a dejà été fait coté backend, il va falloir le faire ici.
   Attention , ce composant est utilisé par 2 autres, donc penser à celà avant de modifier
  */
   getDataFromServer(id : string){
    
  }

  loadData(item){
    this.id = item.id;
    //this.recompense = ColaboDetailComponent.item.recompense;
    this.type = item.type;
    this.proprio = item.proprio;
    this.date = item.date;
    this.info = item.info;
    this.lieu = item.lieu;
    this.secure = item.secure=="true";
    this.notOurSecure = item.notOurSecure =="true"
   
    if (item.urlImg != "") {
      this.urlImg = CommonUrlService.imageUrl + item.urlImg;
    } else {
      this.urlImg = "/assets/images/noImage.png";
    }
  }

  nextStep() {
    document.getElementById("step1").style.display = "none";
    document.getElementById("step2").style.display = "none";
    document.getElementById("step3").style.display = "none";
    this.step++;
    if (this.step > 4) {
      this.step = 4;
    }
    switch (this.step) {

      case 1:
        document.getElementById("step1").style.display = "block";
        break;
      case 2:
        document.getElementById("step1").style.display = "block";

        break;
      case 3: 
        document.getElementById("step2").style.display = "block";
        this.checkTransaction();
        break;
      case 4: 
        this.step = 0;
        document.getElementById("closeGetItemButton").click();
        break;
    }

  }

  previousStep() {
    document.getElementById("step1").style.display = "none";
    document.getElementById("step2").style.display = "none";
    document.getElementById("step3").style.display = "none";
    this.step--;
    if (this.step < 1) {
      this.step = 1;
    }
    switch (this.step) {
      case 1:
        document.getElementById("step1").style.display = "block";
        break;
      case 2:
        document.getElementById("step2").style.display = "block";
        break;
      case 3:
        document.getElementById("step3").style.display = "block";
        break;
    }
  }

  initialize(){
    this.step= 1;
    this.previousStep();
  }

  notifiedOwner(form :NgForm){
    this.notifiedOwnerForm = form;
    document.getElementById("firstFound").style.display="block";
    document.getElementById("secondFound").style.display="none";
    document.getElementById("thirdFound").style.display="none";
    let info = {
      "idItem": this.id,
      "name": this.cleanSpecialChar(form.value['name']),
      "number": this.cleanSpecialChar(form.value['number'])
    };
    this.urlservice.saveFoundVip(info,this.notifiedOwnerObserver);
  }




  cleanSpecialChar(txt: string): string {
    let toConvert = txt + "";
    return toConvert + "".replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  }
  itemDescription
  notifiedOwnerObserver = {
    next: (response) => {
      document.getElementById("firstFound").style.display="none";
      document.getElementById("secondFound").style.display="block";
      document.getElementById("thirdFound").style.display="block";
      this.notifiedOwnerForm.resetForm();
    },
    error: (error) => {
      document.getElementById("firstFound").style.display="none";
      document.getElementById("secondFound").style.display="block";
    }

  }

  //enregistrer les infos de l'utilisateur
  saveInfoCustomer(){
    let name :string = (<HTMLInputElement> document.getElementById("getItemName")).value;
    let phone :string = (<HTMLInputElement> document.getElementById("getItemNumber")).value
    if(name.length>2 && phone.length>8){
      this.loading = true;
      let info = {
        "name":this.cleanSpecialChar( name),
        "phone": "00"+this.cleanSpecialChar( phone)+"",
        "country": this.cleanSpecialChar(""),
        "town": this.cleanSpecialChar(""),
        "itemNom":this.proprio,
        "itemType":this.type,
        "itemCountry":"",
        "itemTown":"",
        "itemDescription":this.info,
      };
      this.urlservice.createCustomerInterest(info,this.saveInfoCustomerObserver);
    }else{
      this.step--;
    }
  }


  //verifier la transaction
  checkTransaction(){
    let trans :string = (<HTMLInputElement> document.getElementById("getItemTransaction")).value;
    if(trans.length>7){
      this.errorTransaction = false;
      this.loading = true;
      this.urlservice.checkTransaction(trans,this.itemId,this.checkTransactionObserver);
    }else{
      this.step--;
    }
  }

  saveInfoCustomerObserver = {
    next: (response) => {
      this.loading = false;
      document.getElementById("closeGetItemButton").click();
      this._router.navigate(['map/'+this.itemId])
      //this.goToPay();
    },
    error: (error) => {
      this.loading = false;
    }

  }

  checkTransactionObserver = {
    next: (response) => {
      this.loading = false;
      if(response){
        document.getElementById("step2").style.display = "none";
        document.getElementById("step3").style.display = "block";
        this.step = 4;

        this.descriptionItem = response;
      }else{
        this.errorTransaction = true;
      }
     
    },
    error: (error) => {
      this.loading = false;
      this.step--;
      this.errorTransaction = true;
    }

  }

  reset(){
    this.step = 1;
    this.previousStep();
  }

}
