import { Component, OnInit, Input } from '@angular/core';
import { CommonUrlService } from '../common-url.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-humainservice-item',
  templateUrl: './humainservice-item.component.html',
  styleUrls: ['./humainservice-item.component.scss']
})
export class HumainserviceItemComponent implements OnInit {
  @Input() nom: string;
  @Input() town: string;
  @Input() url: string;
  @Input() description: string;
  @Input() recorderPhone: string;
  @Input() state: boolean;
  @Input() date: string;
                          
  color = "";
  constructor() { 
    
  }

  getUrl(){
    let url;
    if (this.url != "") {
      url = CommonUrlService.imageUrl + this.url ;
    } else {
      url = "/assets/images/noImage.png";
    }
    return url;
  }
  ngOnInit() {
 
  }

  openDetail() {
    
  } 

   selectColor() : string{
    if(this.color.length<=0){
      let t = "true";
      if(!((this.state+"").indexOf(t)!=-1)){
        this.color = "block-1";
      }else{
        this.color = "block-2";
      }
      
    }
    return this.color;
  }

  getState(){
    let t = "true";
    if((this.state+"").indexOf(t)!=-1){
      return "trouvée"
    }else{
      return "perdue"
    }
  }

  setInfo(){
    var datePipe = new DatePipe("en-US");
     
    document.getElementById("humain_nom").textContent=this.nom;
    document.getElementById("humain_lieu").textContent=this.town;
    document.getElementById("humain_date").textContent=datePipe.transform(this.date, 'dd/MM/yyyy');;
    document.getElementById("humain_contact").textContent=this.recorderPhone;
    document.getElementById("humain_detail").textContent=this.description;
    (<HTMLImageElement>  document.getElementById("humain_image")).src=this.getUrl();
  }
}
