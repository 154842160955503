import { Component, OnInit, Input } from '@angular/core';
import { IFoundComponent } from '../i-found/i-found.component';
import { Ientity } from '../i-found/i-foundEntity/Ientity';
import { Router } from '@angular/router';
@Component({
  selector: 'app-foundcart',
  templateUrl: './foundcart.component.html',
  styleUrls: ['./foundcart.component.scss']
})
export class FoundcartComponent implements OnInit {
  @Input() url: string;
  @Input() type: string;
  @Input() name: string;
  @Input() town: string;
  @Input() id: string;
  @Input() secure: string;
  @Input() notOurSecure: string;
  info: Ientity;
  constructor(private router: Router) { }

  color = "";

  ngOnInit() {
    
  }

  openDetail() {
    // this.info = new Ientity(this.url, this.name, this.town,this.type,this.url);
    // IFoundComponent.setIdAndShow(this.info);
    this.openDetails();
  }

  openDetails() {
    let item = {
      id : "from",
     recompense  : '',
     type :  this.type,
     proprio  : this.name,
     lieu  : this.town,
     urlImg  : this.url,
     secure  : this.secure,
     notOurSecure : this.notOurSecure
    }
    localStorage.setItem('item', JSON.stringify(item));
    if(!(this.secure=='true')){
      this.router.navigate(["/partner/detail",this.id]);
    }else{
      document.getElementById("openSecureMondal").click();
    }
  } 


   selectColor() : string{
    if(this.color.length<=0){
      this.color = "block-"+this.getRandomInt(100)%4;
    }
    return this.color;
  }

   getRandomInt(max:number):number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getTown(){
    var txt = this.town;
    if(this.town.length>18){
      txt = this.town.substring(0,18)+"...";
    }
    return txt;
  }

  getName(){
    var txt = this.name;
    if(this.name.length>18){
      txt = this.name.substring(0,18)+"...";
    }
    return txt;
  }
}
