import { Component, OnInit } from '@angular/core';
import { CommonUrlService } from '../common-url.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-gadgetpage',
  templateUrl: './gadgetpage.component.html',
  styleUrls: ['./gadgetpage.component.scss']
})
export class GadgetpageComponent implements OnInit {
  items = [];
  form : NgForm; 
  description : string;
  constructor(private urlservice:CommonUrlService) { }

  ngOnInit() {
    this.loadGadget();
  }

  loadGadget(){
    this.items =[];
    document.getElementById("first_1").style.display = "block";
    this.urlservice.getGadgets(this.loadGadgetObserver);  
  }
  loadGadgetObserver = {
    next: (response) => {
      document.getElementById("first_1").style.display = "none";
      this.items = response;
    },
    error: (error) => {
      document.getElementById("first_1").style.display = "none";
      console.log(error);
    },
    complete: (resp) => {  

     }
  }

  doCommand(form : NgForm){
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("secondFound").style.display = "none";
    let command = {
      "customerName":form.value['name'],
      "customerPhone":form.value['number'],
      "gadgetId":(<HTMLInputElement>document.getElementById("gadgetId")).value,
      "quantity": form.value['quantity']==""?1:form.value['quantity'] 
    };
    console.log(command);
    this.form = form;
    this.urlservice.setCommand(command,this.doCommandObserver);  
  }

  doCommandObserver = {
    next: (response) => {
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
      this.form.resetForm();
      let link1 = document.getElementById('openValidCommand');
      let link2 = document.getElementById('closeGadgetValidate');
      link1.click();
      link2.click();
    },
    error: (error) => {
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
      console.log(error);
    },
    complete: (resp) => {  

     }
  }

}
