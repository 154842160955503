import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CommonUrlService } from "../common-url.service";
import { NgForm } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-colabo',
  templateUrl: './colabo.component.html',
  styleUrls: ['./colabo.component.scss']
})
export class ColaboComponent implements OnInit {

  clickEvent = new MouseEvent("click", {
    "view": window,
    "bubbles": true,
    "cancelable": false
});

  constructor(private httpClient: HttpClient, private urlservice: CommonUrlService,private activatedRoute: ActivatedRoute) { 
   
  }
  items = [];
  form: NgForm;
  itemId;

  ngOnInit() {
    this.getItemsServer();
    this.itemId = this.activatedRoute.snapshot.paramMap.get('found');
    if(this.itemId==='save'){
      document.getElementById("showFoundForm").dispatchEvent(this.clickEvent);
    }
  }


  ngAfterViewInit() {

  }

  beforeSearch() {
    document.getElementById("first_1").style.display = "block";
    document.getElementById("first_2").style.display = "none";
  }

  afterSearch() {
    document.getElementById("first_2").style.display = "block";
    document.getElementById("first_1").style.display = "none";
  }

  getItemsServer() {
    this.beforeSearch();
    this.urlservice.getLostItems(this.getItemsObserver);
  }

  getItemsObserver = {
    next: (response) => {
      this.items = response;
      this.afterSearch();
      return response;
    },
    error: (error) => {
      console.log('Erreur ! : ' + error);
      this.afterSearch();
      return [];
    }

  }

  saveInfo(form: NgForm) {
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("secondFound").style.display = "none";
    document.getElementById("success-message").style.display = "none";
    this.form = form;
    let user = {
      "quarter": this.cleanSpecialChar(form.value['quartier']),
      "password": this.cleanSpecialChar('1234'),
      "username": this.cleanSpecialChar("colabo-" + form.value['phone']),
      "name": this.cleanSpecialChar(form.value['name']),
      "phone": this.cleanSpecialChar(form.value['phone']),
      "country": this.cleanSpecialChar("Cameroun"),
      "town": this.cleanSpecialChar(form.value['town'])
    };
    this.urlservice.createUser(user, this.createUserObserver);
  }

  cleanSpecialChar(txt: string): string {
    let toConvert = txt + "";
    return toConvert + "".replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  }

  createUserObserver = {
    next: (resp) => {
      this.form.resetForm();
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
      document.getElementById("success-message").style.display = "block";
    },
    error: err => {
      document.getElementById("firstFound").style.display = "none";
      document.getElementById("secondFound").style.display = "block";
    },
    complete: (resp) => {  }
  };

}
