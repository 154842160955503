import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';  

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'helpmoi';
  constructor( public translate: TranslateService) 
  {  
      translate.addLangs(['en', 'fr']);  
      if (localStorage.getItem('locale')) {  
        const browserLang = localStorage.getItem('locale');  
        translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');  
      } else {  
        localStorage.setItem('locale', 'en');  
        translate.setDefaultLang('en');  
      }  
    } 

    changeLang(language: string) {  
      localStorage.setItem('locale', language);  
      this.translate.use(language);  
    }  

  ngOnInit() {
    this.changeLang('fr');
    
  }
  
 
}


