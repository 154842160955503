import { CommonUrlService} from '../../common-url.service';
export class Ientity{
 idCart : string;
 nom : string;
 lieu : string;
 type : string;
 url :string = "assets/images/noImage.png";
 
 constructor(idCart:string,nom:string,lieu:string,type:string,url:string){
    this.idCart = idCart;
    this.lieu = lieu;
    this.nom = nom;
    this.type = type;
    if(url!=""){
      this.url = CommonUrlService.imageUrl+url;
    }

 }

 getId(){
    return this.idCart;
 }
}