import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonUrlService } from '../common-url.service';

@Component({
  selector: 'app-secure-object',
  templateUrl: './secure-object.component.html',
  styleUrls: ['./secure-object.component.scss']
})
export class SecureObjectComponent implements OnInit {
  form: NgForm;
  code: string = "1000";
  customer = null;
  disable: boolean = false;
  preDisable: boolean = false;
  constructor(private urlservice: CommonUrlService) { }

  ngOnInit() {
  }

  isDisable() {
    return this.disable;
  }
  setDisable(bool : boolean){
    this.preDisable = bool;
  }

  saveInfo(form: NgForm) {
    if (!this.isDisable() && (form.valid || this.customer != null)) {
      this.beforeLoad();
      let user = {
        "name": this.cleanSpecialChar(form.value['name']),
        "phone": this.cleanSpecialChar(form.value['phone']),
        "country": this.cleanSpecialChar("Cameroun"),
        "town": this.cleanSpecialChar(form.value['town']),
        "oldPhone": this.cleanSpecialChar(form.value['town']),
        "code": ""
      };

      this.form = form;
      if (this.customer != null) {

        user.oldPhone = this.customer.phone;
        user.code = this.customer.code;

        if (user.name == null || user.name == "null" || user.name == "") {
          user.name = this.customer.name;
        }
        if (user.phone == null || user.phone == "null" || user.phone == "") {
          user.phone = this.customer.phone;
        }
        if (user.town == null || user.town == "null" || user.town == "") {
          user.town = this.customer.town;
        }
        this.urlservice.editCustomer(user, this.updateCustomerObserver);
      } else {
        this.urlservice.createCustomer(user, this.createCustomerObserver);
      }
    }

    //console.log(user);

  }

  cleanSpecialChar(txt: string): string {
    let toConvert = txt + "";
    return toConvert + "".replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
  }


  beforeLoad() {
    document.getElementById("firstFound").style.display = "block";
    document.getElementById("secondFound").style.display = "none";
    document.getElementById("success-message").style.display = "none";
    document.getElementById("success-udate-message").style.display = "none";
    document.getElementById("error-update-message").style.display = "none";
  }
  afterLoad(isError: boolean, isUpdate: boolean) {
    document.getElementById("firstFound").style.display = "none";
    document.getElementById("secondFound").style.display = "block";
    if (!isError) {
      if (isUpdate) {
        document.getElementById("success-udate-message").style.display = "block";
      } else {
        document.getElementById("success-message").style.display = "block";
      }
    }else{
      document.getElementById("error-update-message").style.display = "block";
    }

  }
  createCustomerObserver = {
    next: (resp) => {
      if(resp!=null){
        this.form.resetForm();
        this.customer = null;
        this.code = resp.code;
        this.code = this.code.split(".")[0];
        this.afterLoad(false, false);
      }else{
        this.afterLoad(true, false);
      }


    },
    error: err => {
      this.afterLoad(true, false);
    },
    complete: (resp) => {  }
  };

  updateCustomerObserver = {
    next: (resp) => {
      this.form.resetForm();
      this.customer = null;
      this.code = resp.code;
      this.code = this.code.split(".")[0];
      this.afterLoad(false, true);
    },
    error: err => {
      this.afterLoad(true, true);
    },
    complete: (resp) => { console.log("complete...") }
  };


  checkInfo(form: NgForm) {
    this.beforeLoad();
    this.form = form;
    let phone = this.cleanSpecialChar(form.value['phone']);
    let code = this.cleanSpecialChar(form.value['code']) + ".0";
    document.getElementById("firstFound_check").style.display = "block";
    this.urlservice.getCustomer(phone, code, this.getInfoObserver);
  }

  getInfoObserver = {
    next: (resp) => {
      this.afterLoad(true, false);
      if (resp != null && resp != "") {
        this.customer = resp;
        document.getElementById("firstFound_check").style.display = "none";
        this.form.resetForm();
        (<HTMLInputElement>document.getElementById("name")).disabled;
        (<HTMLInputElement>document.getElementById("name")).value = this.customer.name;
        (<HTMLInputElement>document.getElementById("phone")).value = this.customer.phone;
        (<HTMLInputElement>document.getElementById("town")).value = this.customer.town;
        window.scrollTo(0, 0);
        var clickEvent = new MouseEvent("click", {
          "view": window,
          "bubbles": true,
          "cancelable": false
        });
        document.getElementById("closeCheckModal").dispatchEvent(clickEvent);
        document.getElementById("error_check").style.display = "none";
        this.disable = this.preDisable; 
      } else {
        document.getElementById("firstFound_check").style.display = "none";
        document.getElementById("error_check").style.display = "block";
      }

    },
    error: err => {
      this.afterLoad(true, false);
      document.getElementById("firstFound_check").style.display = "none";
      document.getElementById("error_check").style.display = "block";
    },
    complete: (resp) => { console.log("complete...") }
  };

}
