import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUrlService } from '../common-url.service';
import { Form, NgForm } from '@angular/forms';

@Component({
  selector: 'app-maps-info',
  templateUrl: './maps-info.component.html',
  styleUrls: ['./maps-info.component.scss']
})
export class MapsInfoComponent implements OnInit {

  itemId : any;
  id: any;
  recompense = "Oui";
  type = "";
  proprio = "";
  date = "";
  info = "";
  lieu = "";
  urlImg = "";
  numero ="";
  step: number = 1;
  secure =false;
  notifiedOwnerForm : NgForm;
  descriptionItem;
  loading = false;
  errorTransaction = false;

  constructor(private activatedRoute: ActivatedRoute,private urlservice: CommonUrlService) {
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDataFromServer(this.itemId);
  }

  ngOnInit() {
    document.getElementById("OpenMondal").click();
  }

 
   getDataFromServer(id : string){
    this.urlservice.getInfoToObject(id,this.responseGetInfoObserver);
  }

  loadData(item){
    this.id = item.id;
    this.type = item.type;
    this.proprio = item.nom;
    this.date = item.createdDate;
    this.info = item.town+ " : " + item.description;
    this.lieu = item.town;
    this.numero = item.recorderName + " "+ item.recorderPhone
    this.urlImg = "assets/images/map.png";
  }


  goToPay(id){
    this.urlservice.addOrderForGetInfoToObject(id,this.responseCreateOrderObserver);
  }

  responseGetInfoObserver = {
    next: (response) => {
      if(response.description.length<1){
        this.goToPay(response.id);
      }else{
        document.getElementById("closeMondal").click();
        this.loadData(response);
      }
    },
    error: (error) => {
     
    }
  }

  responseCreateOrderObserver = {
    next: (response) => {
      this.loading = false;
        if(response.ok){
          window.location.href= "https://shanafrika.com/tomcat/psp-api" + "/payement?order=" + response.object.orderId; 
          document.getElementById("closeMondal").click();
        }else{

        }
    },
    error: (error) => {
     

    }

  }

}

