import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUrlService } from '../common-url.service';

@Component({
  selector: 'app-url-confirmation',
  templateUrl: './url-confirmation.component.html',
  styleUrls: ['./url-confirmation.component.scss']
})
export class UrlConfirmationComponent implements OnInit {
  itemId : any;
  constructor(private activatedRoute: ActivatedRoute,private urlservice: CommonUrlService,private _router: Router) { 
    this.itemId = this.activatedRoute.snapshot.paramMap.get('id');
    this.getDataFromServer(this.itemId);
  }

  ngOnInit() {
    document.getElementById("OpenMondal").click();
  }

  getDataFromServer(id : string){
    this.urlservice.getpayment(id,this.responseGetInfoObserver);
  }

  responseGetInfoObserver = {
    next: (response) => {
      document.getElementById("closeMondal").click();
      if(response.ok){
        this._router.navigate(['map/'+response.object.id]);
      }else{
        document.getElementById("errorMessage").style.display = "block";
      }
    },
    error: (error) => {
     
    }
  }


}
