import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DynamicScriptLoaderService } from './script-load-service.service';
import { SearchViewComponent } from './search-view/search-view.component';
import { Routes,RouterModule } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { FoundcartComponent } from './foundcart/foundcart.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { LostformComponent } from './lostform/lostform.component';
import { TranslateModule,TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { FoundObjectComponent } from './found-object/found-object.component';
import { LostCartComponent } from './lost-cart/lost-cart.component';
import { IFoundComponent } from './i-found/i-found.component';
import { ColaboComponent } from './colabo/colabo.component';
import { ColaboDetailComponent } from './colabo-detail/colabo-detail.component';
import { SecureObjectComponent } from './secure-object/secure-object.component';
import { GadgetpageComponent } from './gadgetpage/gadgetpage.component';
import { GadgetItemComponent } from './gadget-item/gadget-item.component';
import { HumainserviceComponent } from './humainservice/humainservice.component';
import { HumainserviceItemComponent } from './humainservice-item/humainservice-item.component';
import { CookieService } from "ngx-cookie-service";
import { MapsInfoComponent } from './maps-info/maps-info.component';
import { UrlConfirmationComponent } from './url-confirmation/url-confirmation.component';

const appRoutes: Routes = [

  { path: 'search/:id', component: SearchViewComponent },
  { path: 'search', component: SearchViewComponent },
  { path: 'report', component: FoundObjectComponent },
  { path: 'partner',  component: ColaboComponent },
  { path: 'partner/:found',  component: ColaboComponent },
  { path: 'partner/detail/:id',  component: ColaboDetailComponent },
  { path: 'saveinfo',  component: SecureObjectComponent },
  { path: 'gadget',  component: GadgetpageComponent },
  { path: 'missing',  component: HumainserviceComponent },
  { path: 'map/:id',  component: MapsInfoComponent },
  { path: 'confirmationurl/:id',  component: UrlConfirmationComponent },
  { path: '', component: HomeComponent },
  { path: '**',   redirectTo: '', pathMatch: 'full' },

];

export const createTranslateLoader =(http:HttpClient)=>{
  return new TranslateHttpLoader(http, './assets/i18n/','.json');
}


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SearchViewComponent,
    NavBarComponent,
    FooterComponent,
    FoundcartComponent,
    LostformComponent,
    FoundObjectComponent,
    LostCartComponent,
    IFoundComponent,
    ColaboComponent,
    ColaboDetailComponent,
    SecureObjectComponent,
    GadgetpageComponent,
    GadgetItemComponent,
    HumainserviceComponent,
    HumainserviceItemComponent,
    MapsInfoComponent,
    UrlConfirmationComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
     FormsModule,
     TranslateModule.forRoot({
      loader : {
        provide : TranslateLoader,
        useFactory : createTranslateLoader,
        deps : [HttpClient]
      }
     }

     )
  ],
  providers: [DynamicScriptLoaderService,CookieService],
  bootstrap: [AppComponent],

})

export class AppModule { }
