import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CommonUrlService {
  public static imageUrlBase = "https://shanafrika.com/tomcat/filesManager/";
  public static imageUrl = CommonUrlService.imageUrlBase+"files/";



  url = "https://shanafrika.com/tomcat/helpmoi/";
  createItemUrl = "";
  getByNameItemUrl = this.url.concat("items/");
  getAllItemsUrl = this.url.concat("items");
  saveObjectFound = this.url.concat("item");
  constructor(private httpClient: HttpClient) { }
  serverUrl = "https://shanafrika.com/tomcat/helpmoi/";



  saveFoundItem(data: any, observer: any): any {
    let url = "foundItem/add";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url), data)
      .subscribe(observer);
  }

  saveLostItem(data: any, observer: any): any {
    let url = "lostItem/add";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url), data)
      .subscribe(observer);
  }

  getFoundItems(observer: any): any {
    let url = "foundItem/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getLostItems(observer: any): any {
    let url = "lostItem/allvip";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  findObjetbyValue(name: string, observer: any): any {
    let url = "foundItem/name?name=" + name;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url), null)
      .subscribe(observer);
  }

  uploadFile(file: File, observer: any): any {
    let url = "updloadImage";
    let formData = new FormData();
    formData.append('file', file);
    this.httpClient
    .post<any[]>(CommonUrlService.imageUrlBase.concat(url), formData)
    .subscribe(observer);
  }

  
  createUser( data : any, observer : any) :any {
    let url = "register";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  createCustomer(data : any, observer : any){
    let url = "customer/add";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);

  }

  createCustomerInterest(data : any, observer : any){
    let url = "customer/add/interest";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);

  }

  editCustomer(data : any, observer : any){
    let url = "customer/update";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);

  }

  getCustomer(phone,code, observer : any){
    let url = "customer/getByCode?phone="+phone+"&code="+code;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  saveFoundVip(data : any, observer : any){
    let url = "foundItem/addVip";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  getGadgets(observer: any): any {
    let url = "gadget/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  setCommand(data : any, observer : any){
    let url = "gadget/command/add";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  addLostHumain(data : any, observer : any){
    let url = "lostHumain/add";
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url),data)
      .subscribe(observer);
  }

  getLostHumains(observer: any): any {
    let url = "lostHumain/all";
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  getFoundItemById(id : string,observer: any): any {
    let url = "foundItem/id?id="+id;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

  checkTransaction(trans : string,id : string,observer: any): any {
    let url = "foundItem/getTransaction?transaction="+trans+"&id="+id;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }


  addOrderForGetInfoToObject(name: string, observer: any): any {
    let url = "foundItem/addOrderForGetInfoToObject?product=" + name;
    this.httpClient
      .post<any[]>(this.serverUrl.concat(url), null)
      .subscribe(observer);
  }

  getInfoToObject(name: string, observer: any): any {
    let url = "foundItem/getObjectInfo?id=" + name;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }
  getpayment(id: string, observer: any): any {
    let url = "foundItem/getOrderForGetInfoToObject?orderId=" + id;
    this.httpClient
      .get<any[]>(this.serverUrl.concat(url))
      .subscribe(observer);
  }

}
