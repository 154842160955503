import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { CommonUrlService } from "../common-url.service";

@Component({
  selector: 'app-lostform',
  templateUrl: './lostform.component.html',
  styleUrls: ['./lostform.component.scss']
})
export class LostformComponent implements OnInit {

  form: NgForm;
  @ViewChild('validateLostItem', { static: false }) openConfirmation: any;
  @ViewChild('closeModal', { static: false }) closeModal: any;
  file: File;
  urlImg : string ="";
  item ;
  constructor(private urlservice: CommonUrlService) { }

  ngOnInit() {
  }

  saveToTypeField() {
    var type = (<HTMLInputElement>document.getElementById("type1")).value;
    if ((<HTMLInputElement>document.getElementById("type1")).value === "other") {
      type = "";
      document.getElementById("type_block").style.display = "block";
    } else {
      document.getElementById("type_block").style.display = "none";
    }
    (<HTMLInputElement>document.getElementById("type")).value = type;
  }

  saveInfo(form: NgForm) {
    this.form = form;
    let type = form.value['type1'];
    if (type === "other") {
      type = form.value['type'];
    }
    let lieu = form.value['details'];
    let details = form.value['details'];
    let tel = form.value['phone'];
    let name = form.value['name'];
    let isVip = form.value['isVip'];

    let item =
    {
      "type": type,
      "country": "Cameroun",
      "town": lieu,
      "description": details,
      "imageUrl": "",
      "recorderPhone": tel,
      "recorderName": name,
      "nom": name,
      'vipService': isVip.toLowerCase() == 'true'
    }
    document.getElementById("loading").style.display = "block";
    document.getElementById("button").style.display = "none";
    this.item = item;

    if(this.file!=null){
      this.urlservice.uploadFile(this.file, this.saveImageObserver);  
    }else{
      this.urlservice.saveLostItem(this.item, this.saveLostItemObserver);
    } 
  }


  saveLostItemObserver = {
    next: (response) => {
      document.getElementById("loading").style.display = "none";
      document.getElementById("button").style.display = "block";
      this.openConfirmation.nativeElement.click();
      this.closeModal.nativeElement.click();
    },
    error: (error) => {
      console.log(error);
      document.getElementById("loading").style.display = "none";
      document.getElementById("button").style.display = "block";
    }

  }

  selectFile(event) {
    this.uploadFile(event.target.files);
  }

  uploadFile(files: FileList) {
    this.file  = null;
    if (files.length == 0) {
      return;
    }
    this.file  = files[0];
  }


  saveImageObserver = {
    next: (response) => {
      this.item.imageUrl=response.url;
      if(this.file!=null){
        this.file = null;
        this.urlservice.saveLostItem(this.item, this.saveLostItemObserver);
      }
    },
    error: (error) => {
      console.log(error);
      document.getElementById("loading").style.display = "none";
      document.getElementById("button").style.display = "block";
    },
    complete: (resp) => {  

     }
  }
}
