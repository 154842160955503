import { Component, OnInit, ViewChild ,ElementRef, AfterViewInit} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { CommonUrlService } from "../common-url.service";
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-search-view',
  templateUrl: './search-view.component.html',
  styleUrls: ['./search-view.component.scss']
})


export class SearchViewComponent implements OnInit,AfterViewInit {

  @ViewChild('openModal', {static: false}) openModal: any;


  beforeSearch() {
  
    document.getElementById("first").style.display = "block";
    document.getElementById("notfound").style.display = "none";
    (<HTMLInputElement>document.getElementById("searchInput")).disabled = true;
    
    
  }
  afterSearch() {
    document.getElementById("second").style.display = "block";
    document.getElementById("first").style.display = "none";
    (<HTMLInputElement>document.getElementById("searchInput")).disabled = false;
    if(this.items.length<1){
      document.getElementById("notfound").style.display = "block";
    }else{
      document.getElementById("notfound").style.display = "none";
    }
  }

   removeSecure(item) {

    return !item.secure;
  }

  getItemsObserver = {
    next: (response) => {
      let tempItems = response;
      //this.items = tempItems.filter(this.removeSecure);
      this.items = tempItems;
      this.afterSearch();
      return response;
    },
    error: (error) => {
      console.log('Erreur ! : ' + error);
      this.afterSearch();
      return [];
    }
  }

  getItemsServer() {
    this.urlservice.getFoundItems(this.getItemsObserver);
  }

  items = [];

  constructor(private httpClient: HttpClient,private urlservice:CommonUrlService,private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    //this.getItemsServer();
    this.afterSearch();
  
  }
  ngAfterViewInit(){
    let bool = this.activatedRoute.snapshot.paramMap.get('id');
    console.log('bool');
    if(bool){
     this.showRegister();
    }
  }

  findObjetbyValue(value: string) {
    this.urlservice.findObjetbyValue(value,this.getItemsObserver);
  }


  onSearch(form: NgForm) {
    const value = form.value['name'];
    this.items = [];
    this.findObjetbyValue(value);
   
  }

  showRegister(){
    this.openModal.nativeElement.click();
    
  }


 

}
