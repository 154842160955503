import { Component, OnInit, Input } from '@angular/core';
import { CommonUrlService } from '../common-url.service';

@Component({
  selector: 'app-gadget-item',
  templateUrl: './gadget-item.component.html',
  styleUrls: ['./gadget-item.component.scss']
})
export class GadgetItemComponent implements OnInit {
  @Input() id: string;
  @Input() desc: string;
  @Input() price: string;
  @Input() name: string;
  @Input() url : string;
  constructor() { }

  color = "";

  ngOnInit() {
    
  }

  openDetail() {
    let gadget = document.getElementById('gadgetLabel');
    (<HTMLInputElement>gadget).value=this.name;
    document.getElementById('gadgetDesc').innerHTML =this.desc;
    let gadgetId = document.getElementById('gadgetId');
    (<HTMLInputElement>gadgetId).value=this.id;
    let link = document.getElementById('BgadgetValidate');
    link.click();
  }  

   selectColor() : string{
    if(this.color.length<=0){
      this.color = "block-"+this.getRandomInt(100)%4;
    }
    return this.color;
  }

   getRandomInt(max:number):number {
    return Math.floor(Math.random() * Math.floor(max));
  }

  getImage(urlImg:string){
    if (urlImg!= "") {
      return CommonUrlService.imageUrl + urlImg;
    } else {
      return "/assets/images/noImage.png";
    }
  }

}
